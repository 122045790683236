import './style.scss';
import 'vite/modulepreload-polyfill';
import 'virtual:svg-icons-register';
import './src/js/functions/set-background-image';

import './src/components/form/vendor/validate.js';
import './src/components/GA_Events/GA_Events.ts'
window.$ = $;

import './src/components/donation-form-frb/donation-form-frb.js';
import './src/components/donation-form-frb/partials/donation-form-frb-thankyou.js';

const components = [
  '.react', '.vue', '.accordion', '.jquery',
  '.js-copy-button', '.counter', '.header', '.header--small', '.list--download',
  '.maps', '.frb-teaser', '.overlay', '.popup-layer',
  '.tx-indexedsearch-res', '.parallax-full__item',
  '.table', '.audio', '.lightbox', '.social-feed-curator',
  '.form', '.form[data-validate="true"]', '.tx-fp-masterquiz',
  '.teaser__news-slider', '.teaser--news',
  '.teaser--small', '.teaser-slider',
  '.content-slider', '.download-extended', '.gallery', '.jumpmarks',
  '.quote-slider', '.tab', '.stage', '.video-loop', '.youtube-embed',
  '.donate', '.donate-slider', '.donate-box',
  '.donation-form--findock', '.donation-form--checkout', '.findock-thank-page',
  '.product-list', '.product-list-filter', '.product-detail-infobox', '.product-cart-cta', '.checkout-basket__item', '.checkout-basket__wrapper', '.tab.checkout',
  '.checkout-personal__wrapper', '.checkout-summary__wrapper', '.checkout-thank-page'
];

async function init() {
  console.log('[initalized] main.ts');
  // Dynamic importing of the component js
  for (let i = 0; i < components.length; i++) {
    switch (components[i]) {
      case '.react':
        if (document.querySelector(components[i])) {
          console.log('[detected] react component');
          const react = await import('./src/components/react/react');
          react.init();
        }
        break;
      case '.vue':
        if (document.querySelector(components[i])) {
          console.log('[detected] vue component');
          const vue = await import('./src/components/vue/vue');
          vue.init();
        }
        break;
      case '.accordion':
        if (document.querySelector(components[i])) {
          console.log('[detected] accordion component');
          const accordion = await import(
            './src/components/accordion/accordion'
          );
          accordion.default(document.querySelectorAll(components[i]))
        }
        break;
      case '.jquery':
        if (document.querySelector(components[i])) {
          console.log('[detected] jquery component');
          const jquery = await import('./src/components/jquery/jquery');
          jquery.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.js-copy-button':
        if (document.querySelector(components[i])) {
          console.log('[detected] button component');
          const button = await import(
            './src/components/button-copy/button-copy'
          );
          button.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.counter':
        if (document.querySelector(components[i])) {
          console.log('[detected] counter component');
          const counter = await import(
            './src/components/counter/counter'
          );
          counter.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.header':
        if (document.querySelector(components[i])) {
          console.log('[detected] nav-main component');
          const navMain = await import(
            './src/components/nav-main/nav-main'
          );
          navMain.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.header--small':
        if (document.querySelector(components[i])) {
          console.log('[detected] header--small component');
          const headerSmall = await import(
            './src/components/header/header'
          );
          headerSmall.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.list--download':
        if (document.querySelector(components[i])) {
          console.log('[detected] list--download component');
          const list = await import(
            './src/components/list/list'
          );
          list.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.maps':
        if (document.querySelector(components[i])) {
          console.log('[detected] maps component');
          const maps = await import(
            './src/components/maps/maps-contact'
          );
          maps.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.frb-teaser':
        if (document.querySelector(components[i])) {
          console.log('[detected] frb-teaser component');
          const frbTeaser = await import(
            './src/components/frb-teaser/frb-teaser'
          );
          frbTeaser.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.overlay':
        if (document.querySelector(components[i])) {
          console.log('[detected] overlay component');
          const overlay = await import(
            './src/components/overlay/overlay'
          );
          overlay.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.popup-layer':
        if (document.querySelector(components[i])) {
          console.log('[detected] popup-layer component');
          const popupLayer = await import(
            './src/components/popup-layer/popup-layer'
          );
          popupLayer.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.tx-indexedsearch-res':
        if (document.querySelector(components[i])) {
          console.log('[detected] indexedsearch-res component');
          const news = await import(
            './src/components/news/news'
          );
          news.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.parallax-full__item':
        if (document.querySelector(components[i])) {
          console.log('[detected] parallax-full component');
          const parallaxFull = await import(
            './src/components/parallax-full/parallax-full'
          );
          parallaxFull.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.table':
        if (document.querySelector(components[i])) {
          console.log('[detected] table component');
          const table = await import(
            './src/components/table/table'
          );
          table.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.audio':
        if (document.querySelector(components[i])) {
          console.log('[detected] audio component');
          const audio = await import(
            './src/components/audio/audio'
          );
          audio.default(document.querySelectorAll(components[i]));;
        }
        break;
      case '.lightbox':
        if (document.querySelector(components[i])) {
          console.log('[detected] lightbox component');
          const lightbox = await import(
            './src/components/lightbox/lightbox'
          );
          lightbox.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.social-feed-curator':
        if (document.querySelector(components[i])) {
          console.log('[detected] social-feed-curator component');
          const socialFeedCurator = await import(
            './src/components/social-feed-curator/social-feed-curator'
          );
          socialFeedCurator.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.form[data-validate="true"]':
        if (document.querySelector(components[i])) {
          console.log('[detected] form validation component');
          const formVal = await import(
            './src/components/form/form-validation'
          );
          formVal.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.form':
        if (document.querySelector(components[i])) {
          console.log('[detected] form component');
          const form = await import(
            './src/components/form/form'
          );
          form.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.tx-fp-masterquiz':
        if (document.querySelector(components[i])) {
          console.log('[detected] form component');
          const form = await import(
            './src/components/miniquiz/miniquiz'
          );
          form.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.teaser__news-slider':
        if (document.querySelector(components[i])) {
          console.log('[detected] teaser news slider component');
          const teaserNewsSlider = await import(
            './src/components/teaser-news/teaser-news-slider'
          );
          teaserNewsSlider.default(document.querySelectorAll(components[i]));
        }
        break;

      case '.teaser--news':
        if (document.querySelector(components[i])) {
          console.log('[detected] teaser news component');
          const teaserNewsSlider = await import(
            './src/components/teaser-news/teaser-news'
          );
          teaserNewsSlider.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.teaser--small':
        if (document.querySelector(components[i])) {
          console.log('[detected] teaser small component');
          const teaserSmall = await import(
            './src/components/teaser-small/teaser-small'
          );
          teaserSmall.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.teaser-slider':
        if (document.querySelector(components[i])) {
          console.log('[detected] teaser slider component');
          const teaserSlider = await import(
            './src/components/teaser-slider/teaser-slider'
          );
          teaserSlider.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.content-slider':
        if (document.querySelector(components[i])) {
          console.log('[detected] content slider component');
          const teaserSlider = await import(
            './src/components/content-slider/content-slider'
          );
          teaserSlider.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.download-extended':
        if (document.querySelector(components[i])) {
          console.log('[detected] download-extended component');
          const downloadExtended = await import(
            './src/components/download-extended/download-extended'
          );
          downloadExtended.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.gallery':
        if (document.querySelector(components[i])) {
          console.log('[detected] gallery component');
          const gallery = await import(
            './src/components/gallery/gallery'
          );
          gallery.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.jumpmarks':
        if (document.querySelector(components[i])) {
          console.log('[detected] jumpmark component');
          const jumpmarks = await import(
            './src/components/jumpmarks/jumpmarks'
          );
          jumpmarks.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.quote-slider':
        if (document.querySelector(components[i])) {
          console.log('[detected] quote-new component');
          const quote = await import(
            './src/components/quote-slider/quote-slider'
          );
          quote.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.tab':
        if (document.querySelector(components[i])) {
          console.log('[detected] tab component');
          const tab = await import(
            './src/components/tab/tab'
          );
          tab.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.stage':
        if (document.querySelector(components[i])) {
          console.log('[detected] stage component');
          const stage = await import(
            './src/components/stage/stage'
          );
          stage.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.video-loop':
        if (document.querySelector(components[i])) {
          console.log('[detected] video-loop component');
          const videoLoop = await import(
            './src/components/video-loop/video-loop'
          );
          videoLoop.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.youtube-embed':
        if (document.querySelector(components[i])) {
          console.log('[detected] youtube-embed component');
          const youtubeEmbed = await import(
            './src/components/youtube-embed/youtube-embed'
          );
          youtubeEmbed.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.donate':
        if (document.querySelector(components[i])) {
          console.log('[detected] donate component');
          const donate = await import(
            './src/components/donate/donate'
          );
          donate.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.donate-slider':
        if (document.querySelector(components[i])) {
          if (document.querySelector(components[i])) {
            console.log('[detected] donate-slider component');
            const donateSlider = await import('./src/components/donate-slider/donate-slider');
            donateSlider.init();
          }
        }
        break;
      case '.donate-box':
        if (document.querySelector(components[i])) {
          if (document.querySelector(components[i])) {
            console.log('[detected] donate-box component');
            const donateBox = await import('./src/components/donate-box/donate-box');
            donateBox.default(document.querySelectorAll(components[i]));
          }
        }
        break;
      case '.donation-form--findock':
        if (document.querySelector(components[i])) {
          console.log('[detected] donation-form--findock component');
          const donateFormFindock = await import(
            './src/components/donation-form-findock/donation-form-findock'
          );
          donateFormFindock.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.donation-form--checkout':
        if (document.querySelector(components[i])) {
          console.log('[detected] donation-form-checkout component');
          const donateFormCheckout = await import(
            './src/components/donation-form-checkout/donation-form-checkout'
          );
          donateFormCheckout.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.findock-thank-page':
        if (document.querySelector(components[i])) {
          console.log('[detected] donation-form--findock thankyou component');
          const donateFormFindockTY = await import(
            './src/components/donation-form-findock/partials/donation-form-findock-thankyou'
          );
          donateFormFindockTY.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.product-list':
        if (document.querySelector(components[i])) {
          console.log('[detected] product list component');
          const productList = await import(
            './src/components/product-list/product-list'
          );
          productList.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.product-list-filter':
        if (document.querySelector(components[i])) {
          console.log('[detected] product list filter component');
          const productListFilter = await import(
            './src/components/product-list-filter/product-list-filter'
          );
          productListFilter.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.product-detail-infobox':

        if (document.querySelector(components[i])) {
          console.log('[detected] product detail infobox component');
          const productDetailInfobox = await import(
            './src/components/product-detail-infobox/product-detail-infobox'
          );
          productDetailInfobox.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.checkout-basket__wrapper':
        if (document.querySelector(components[i])) {
          console.log('[detected] checkout basket component');
          const productCheckoutBasket = await import(
            './src/components/checkout/checkout-basket/checkout-basket'
          );
          productCheckoutBasket.default(document.querySelectorAll(components[i]));

          const productDetailInfobox = await import(
            './src/components/product-detail-infobox/product-detail-infobox'
          );
          productDetailInfobox.default(document.querySelectorAll(components[i]));

        }
        break;
      case '.checkout-personal__wrapper':
        if (document.querySelector(components[i])) {
          console.log('[detected] checkout personal component');
          const productCheckoutPersonal = await import(
            './src/components/checkout/checkout-personal/checkout-personal'
          );
          productCheckoutPersonal.default(document.querySelectorAll(components[i]));

        }
        break;
      case '.product-cart-cta':
        if (document.querySelector(components[i])) {
          console.log('[detected] sticky cart cta component');
          const productCartCta = await import(
            './src/components/product-cart-cta/product-cart-cta'
          );
          productCartCta.default(document.querySelectorAll(components[i]));
        }
        break;
      case '.tab.checkout':
        if (document.querySelector(components[i])) {
          console.log('[detected] checkout component');
          const checkout = await import(
            './src/components/checkout/checkout'
          );
          checkout.default(document.querySelectorAll(components[i]));
        }
        break;

      case '.checkout-summary__wrapper':
        if (document.querySelector(components[i])) {
          console.log('[detected] checkout summary component');
          const checkoutSummary = await import(
            './src/components/checkout/checkout-summary/checkout-summary'
          );
          checkoutSummary.default(document.querySelectorAll(components[i]));
        }
        break;
        case '.checkout-thank-page':
        if (document.querySelector(components[i])) {
          console.log('[detected] checkout thankyou component');
          const checkoutThankyou = await import(
            './src/components/checkout/checkout-thankyou/checkout-thankyou'
          );
          checkoutThankyou.default(document.querySelectorAll(components[i]));
        }
        break;
      default:
        break;
    }
  }

  // If the the page is served on gitlab pages, expand all links with the base path
  // variable
  if (document.location.hostname.includes('pages-brandung.de')) {
    import('./src/partials/base-path-helper').then((module) =>
      module.setBasePathInTemplates(),
    );
  }
}

init();
